import { Text, List } from '@chakra-ui/react';
import tokens from 'design-tokens';
import styled from 'styled-components';
import { DApiRow } from './DApiRow';
import { DapiType } from '../types';
import { ChevronDown } from '../custom/ChevronDown';
import { breakpoints } from '../theme';
import { useEffect, useRef, useState } from 'react';

interface Props {
    dapis: DapiType[];
    groupName: string;
    chainName: string;
}

export const DApiGroup = ({ dapis, groupName, chainName }: Props) => {
    const listedDapis = dapis.filter((dapi) => dapi.stage === 'active');

    const [isOnTop, setIsOnTop] = useState(false);

    const summaryRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleScroll = () => {
            if (summaryRef.current) {
                const rect = summaryRef.current.getBoundingClientRect();
                setIsOnTop(rect.top <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (listedDapis.length === 0) {
        return null;
    }

    return (
        <Container>
            <summary ref={summaryRef} className={isOnTop ? 'on-top' : ''}>
                <Text className="dapis-group-name">
                    {groupName}
                    <span className="dapis-group-count">({listedDapis.length})</span>
                </Text>
                <ChevronDown />
            </summary>
            <List className="dapis-group-list">
                {listedDapis
                    .filter((dapi) => dapi.stage === 'active')
                    .map((dapi, index) => (
                        <DApiRow key={index} dapi={dapi} index={index} chainName={chainName} />
                    ))}
            </List>
        </Container>
    );
};

const Container = styled('details')`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${tokens.core.spacing[250]}px;
    position: relative;

    summary {
        list-style: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        ${tokens.semantic.font.subtitle[5]};
        gap: ${tokens.core.spacing[150]}px;
        font-weight: 600;
        color: ${tokens.core.color.base.light};
        z-index: 10;

        .dapis-group-count {
            color: ${tokens.core.color.gray[500]};
            font-size: ${tokens.core.font.size[112]}px;
            line-height: ${tokens.core.font['line-height'].s};
            font-weight: 400;
            margin-left: 0.5ch;
        }

        &.on-top {
            background-color: ${tokens.core.color.base.dark};
            padding-top: ${tokens.core.spacing[100]}px;
        }

        svg {
            transition: all 0.3s ease;
            color: ${tokens.core.color.gray[300]};
        }
    }

    &[open] {
        summary {
            padding-bottom: ${tokens.core.spacing[100]}px;

            svg {
                transform: rotate(180deg);
            }
        }
    }

    &:hover {
        summary,
        svg {
            color: ${tokens.core.color.gray[300]};
        }
    }

    .dapis-group-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
        padding: 0;
    }

    @media (min-width: ${breakpoints.s}px) {
        margin-bottom: ${tokens.core.spacing[400]}px;
    }
`;
