import { dapis } from '@api3/dapi-management';
import { VStack } from '@chakra-ui/react';
import styled from 'styled-components';
import tokens from 'design-tokens';
import { breakpoints } from '../theme';
import { ScrollToTopButton } from '../custom/ScrollToTop';
import groupBy from 'lodash/groupBy';
import { DApiGroup } from './DApiGroup';
import { useCurrentChain } from '../hooks/use-current-chain';

const groupedDapis = groupBy(dapis, 'metadata.category');

export const Welcome = () => {
    const chainName = useCurrentChain();

    return (
        <StyledWelcome>
            <div className="page-header">
                <h1>dAPI Catalog</h1>
                <span>{dapis.filter((dapi) => dapi.stage === 'active').length} Data feeds available for purchase</span>
            </div>
            <div className="page-content">
                {Object.keys(groupedDapis)
                    .sort()
                    .map((key) => (
                        <DApiGroup key={key} dapis={groupedDapis[key]} groupName={key} chainName={chainName} />
                    ))}
                <ScrollToTopButton />
            </div>

            <img className="bg-lines" src="bg.png" />
        </StyledWelcome>
    );
};

const StyledWelcome = styled(VStack)`
    display: flex;
    width: 100%;
    max-width: 814px;
    padding: 0 ${tokens.core.spacing[100]}px;

    .bg-lines {
        position: absolute;
        bottom: 0;
        width: 2000px;
        height: 1000px;
        overflow: visible;
        z-index: -1;
    }

    .page-header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: ${tokens.core.spacing[50]}px;
        padding: ${tokens.core.spacing[250]}px 0;

        h1 {
            ${tokens.semantic.font.heading[6]};
            color: ${tokens.core.color.base.light};
            font-weight: 600;
            margin: 0;
        }

        span {
            ${tokens.semantic.font.body[5]};
            font-size: ${tokens.core.font.size[112]}px;
            line-height: ${tokens.core.font['line-height'].m};
            color: ${tokens.core.color.gray[200]};
            margin-top: ${tokens.core.spacing[50]}px;

            a {
                color: ${tokens.core.color.blue[500]};
            }
        }
    }

    .page-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    @media (min-width: ${breakpoints.s}px) {
        .page-header {
            padding: ${tokens.core.spacing[400]}px 0;

            h1 {
                font-size: 56px;
                line-height: 110%;
            }

            span {
                font-size: 22px;
                font-weight: 400;
                line-height: 130%;
            }
        }
    }

    @media (min-width: ${breakpoints.m}px) {
        padding: 0;
    }
`;
