import styled from 'styled-components';
import { useDetectScroll } from '../hooks/use-detect-scroll';
import tokens from 'design-tokens';

export const ScrollToTopButton = () => {
    const { isScrolled } = useDetectScroll();

    return (
        <StyledGoUpButton
            className={isScrolled ? 'visible' : ''}
            onClick={() =>
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            }
        >
            <div className="arrow" />
        </StyledGoUpButton>
    );
};

const StyledGoUpButton = styled.button`
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 100;
    border: none;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    color: red;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    opacity: 1;
    display: none;

    background-color: ${tokens.core.color.gray[300]};
    transition: all 0.3s ease-in-out;

    .arrow {
        border: solid ${tokens.core.color.gray[500]};
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    &:hover {
        background-color: ${tokens.core.color.gray[200]};

        .arrow {
            border-color: ${tokens.core.color.base.dark};
        }
    }

    &.visible {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
