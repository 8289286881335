import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import tokens from 'design-tokens';
import { LogoMobile } from '../custom/LogoMobile';
import { LogoDesktop } from '../custom/LogoDesktop';
import { breakpoints } from '../theme';
import { MARKET_URL } from '../constants';

const Header = () => {
    return (
        <StyledHeader as="header" p={4}>
            <a href={MARKET_URL}>
                <Flex align="flex-start" cursor="pointer" gap={'12px'}>
                    <div className="header-container-inner">
                        <LogoMobile className="header-logo-mobile" />
                        <LogoDesktop className="header-logo-desktop" />
                    </div>
                </Flex>
            </a>
        </StyledHeader>
    );
};

const StyledHeader = styled(Flex)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${tokens.core.spacing[125]}px ${tokens.core.spacing[100]}px;
    color: ${tokens.core.color.base.light};
    width: 100%;

    &:hover {
        color: ${tokens.core.color.gray[100]};
    }

    .header-container-inner {
        display: flex;
        align-items: center;
        gap: 0px;
        width: 100%;

        .header-logo-mobile {
            display: block;
        }

        .header-logo-desktop {
            display: none;
        }
    }

    @media (min-width: ${breakpoints.m}px) {
        padding: ${tokens.core.spacing[150]}px ${tokens.core.spacing[250]}px;

        .header-container-inner {
            height: 24px;

            .header-logo-mobile {
                display: none;
            }

            .header-logo-desktop {
                display: block;
            }
        }
    }
`;

export default Header;
