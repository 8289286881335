import { useEffect } from 'react';
import { api3Chains } from '@api3/dapi-management';
import { useSearchParams } from 'react-router-dom';

const CHAIN_QUERY_PARAM = 'chain';
const DEFAULT_CHAIN_ALIAS = 'ethereum';

export const useCurrentChain = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const urlChain = searchParams.get(CHAIN_QUERY_PARAM);

    useEffect(() => {
        const invalidChainAlias = !api3Chains.CHAINS.some((chain) => chain.alias === urlChain);
        if (invalidChainAlias) {
            searchParams.set(CHAIN_QUERY_PARAM, DEFAULT_CHAIN_ALIAS);
            setSearchParams(searchParams, { replace: true });
        }
    }, [urlChain, searchParams, setSearchParams]);

    return urlChain || DEFAULT_CHAIN_ALIAS;
};
