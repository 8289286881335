import { useEffect, useState } from 'react';

const NAVBAR_HEIGHT = 56;

export const useDetectScroll = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        // Detect if page scroll is more than navbar height
        const handleScroll = () => {
            setIsScrolled(window.scrollY > NAVBAR_HEIGHT);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return { isScrolled };
};
