import tokens from 'design-tokens';

export type BreakpointKeys = keyof typeof tokens.core.breakpoints;

const incomingBreakpoints = tokens.core.breakpoints; // as Record<BreakpointKeys, string>;

// // Get number part from strings such as 1024px, 768px etc.
const getValue = (str: string) => Number(str.match(/\d+/g)?.join(''));

export const breakpoints = Object.keys(incomingBreakpoints).reduce(
    (acc: Record<BreakpointKeys, number>, key: string) => {
        const keyValue = key as BreakpointKeys;
        acc[keyValue] = getValue(incomingBreakpoints[keyValue]);
        return acc;
    },
    {} as Record<BreakpointKeys, number>
);
