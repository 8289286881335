import styled, { css } from 'styled-components';
import { SymbolLogo } from '@api3/logos';
import { Skeleton } from '@chakra-ui/react';
import { BreakpointKeys, breakpoints } from '../theme';
import { useWindowDimensions } from '../hooks/use-window-dimensions';

interface Props {
    dapiName?: string;
    size?: BreakpointKeys;
}

const DatafeedLogos = (props: Props) => {
    const { dapiName, size } = props;

    const { width } = useWindowDimensions();

    const logos = dapiName?.split('/');
    const sizeClassName = size || (width <= breakpoints.m ? (width <= breakpoints.s ? 'small' : 'medium') : 'large');
    const className = `feed-container ${String(sizeClassName)}`;

    if (!logos || logos.length === 0) {
        return (
            <Container className={className} aria-hidden>
                <Skeleton className="feed" />
                <Skeleton className="feed" />
            </Container>
        );
    }

    return (
        <Container className={className} aria-hidden>
            <img className="feed" src={SymbolLogo(logos[0])} alt={`${logos[0]} logo`} />
            <img className="feed" src={SymbolLogo(logos[1])} alt={`${logos[1]} logo`} />
        </Container>
    );
};

const smallSize = css`
    .feed {
        width: 21px;
        height: 21px;
        min-width: 21px;
        min-height: 21px;
    }
`;

const mediumSize = css`
    .feed {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
    }
`;

const largeSize = css`
    .feed {
        width: 42px;
        height: 42px;
        min-width: 42px;
        min-height: 42px;
    }
`;

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-size: border-box;

    .feed {
        z-index: 3;
        position: relative;
        border-radius: 50%;

        &:nth-child(2) {
            z-index: 2;
            margin-left: -4px;
        }
    }

    // Default size
    ${smallSize}

    // Override default size if specific size is provided
    &.small {
        ${smallSize}
    }
    &.medium {
        ${mediumSize}
    }
    &.large {
        ${largeSize}
    }
`;

export default DatafeedLogos;
