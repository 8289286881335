import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';
import Header from './components/Header';
import { Welcome } from './components/Welcome';
import styled from 'styled-components';

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Container>
                <Routes>
                    <Route path="/" element={<Welcome />} />
                </Routes>
            </Container>
        </BrowserRouter>
    );
}

const Container = styled(VStack)``;

export default App;
