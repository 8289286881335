import { ComponentPropsWithoutRef } from 'react';

export const LogoDesktop = (props: ComponentPropsWithoutRef<'svg'>) => {
    return (
        <svg width="172" height="24" viewBox="0 0 172 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.270809 23.9949H5.71791L10.7248 4.93359L0.0397202 23.591C-0.0703222 23.7766 0.0617286 23.9949 0.270809 23.9949Z"
                fill="url(#paint0_linear_15741_197433)"
            />
            <path
                d="M24.9302 18.9961L5.7168 23.9962H27.3731C27.5822 23.9962 27.7143 23.7778 27.6042 23.5922L24.9302 18.9961Z"
                fill="url(#paint1_linear_15741_197433)"
            />
            <path
                d="M21.9839 16.081L24.933 18.9959L13.9508 0.131006C13.8518 -0.0436686 13.5877 -0.0436686 13.4776 0.131006L10.7266 4.93456L21.9839 16.081Z"
                fill="url(#paint2_linear_15741_197433)"
            />
            <path
                d="M5.7168 23.9901L9.67776 22.9529L9.65575 9.01172L5.7168 23.9901Z"
                fill="url(#paint3_linear_15741_197433)"
            />
            <path
                d="M9.66016 9.01661L21.9849 16.08L10.7276 4.93359L9.66016 9.01661Z"
                fill="url(#paint4_linear_15741_197433)"
            />
            <path
                d="M9.67578 22.9559L24.9277 18.993L21.9785 16.0781L9.67578 22.9559Z"
                fill="url(#paint5_linear_15741_197433)"
            />
            <path
                d="M9.68216 22.9592L12.3228 21.4854L9.66016 11.9766L9.68216 22.9592Z"
                fill="url(#paint6_linear_15741_197433)"
            />
            <path
                d="M21.982 16.0863L19.385 14.6016L12.3203 21.4903L21.982 16.0863Z"
                fill="url(#paint7_linear_15741_197433)"
            />
            <path d="M9.6582 11.9937L19.386 14.6138L9.6582 9.03516V11.9937Z" fill="url(#paint8_linear_15741_197433)" />
            <path
                d="M15.8445 14.0393H17.3411L9.66016 11.9766L10.2324 14.0284L15.8335 14.0393H15.8445Z"
                fill="url(#paint9_linear_15741_197433)"
            />
            <path
                d="M10.2324 14.0273L12.3229 21.4838L13.7973 20.0427L11.0246 15.3483L10.2324 14.0273Z"
                fill="url(#paint10_linear_15741_197433)"
            />
            <path
                d="M17.3402 14.0391L13.7969 20.0435L19.387 14.5958L17.3402 14.0391Z"
                fill="url(#paint11_linear_15741_197433)"
            />
            <path
                d="M15.8446 14.0383L10.2324 14.0273L11.0247 15.3479L15.8446 14.0383Z"
                fill="url(#paint12_linear_15741_197433)"
            />
            <path
                d="M14.5333 18.7787L11.0234 15.3398L13.7961 20.0342L14.5333 18.7787Z"
                fill="url(#paint13_linear_15741_197433)"
            />
            <path
                d="M17.3408 14.0391H15.8445L14.5352 18.788L17.3408 14.0391Z"
                fill="url(#paint14_linear_15741_197433)"
            />
            <path
                d="M0.270809 23.9949H5.71791L10.7248 4.93359L0.0397202 23.591C-0.0703222 23.7657 0.0617286 23.9949 0.270809 23.9949Z"
                fill="url(#paint15_linear_15741_197433)"
            />
            <path
                d="M24.9302 18.9961L5.7168 23.9962H27.3731C27.5822 23.9962 27.7143 23.7778 27.6042 23.5922L24.9302 18.9961Z"
                fill="url(#paint16_linear_15741_197433)"
            />
            <path
                d="M21.9839 16.081L24.933 18.9959L13.9508 0.131006C13.8518 -0.0436686 13.5877 -0.0436686 13.4776 0.131006L10.7266 4.93456L21.9839 16.081Z"
                fill="url(#paint17_linear_15741_197433)"
            />
            <path
                d="M5.7168 23.9901L9.67776 22.9529L9.65575 9.01172L5.7168 23.9901Z"
                fill="url(#paint18_linear_15741_197433)"
            />
            <path
                d="M9.67578 22.9559L24.9277 18.993L21.9785 16.0781L9.67578 22.9559Z"
                fill="url(#paint19_linear_15741_197433)"
            />
            <path
                d="M9.68216 22.9592L12.3228 21.4854L9.66016 11.9766L9.68216 22.9592Z"
                fill="url(#paint20_linear_15741_197433)"
            />
            <path
                d="M21.982 16.0863L19.385 14.6016L12.3203 21.4903L21.982 16.0863Z"
                fill="url(#paint21_linear_15741_197433)"
            />
            <path
                d="M10.2324 14.0273L12.3229 21.4838L13.7973 20.0427L11.0246 15.3483L10.2324 14.0273Z"
                fill="url(#paint22_linear_15741_197433)"
            />
            <path
                d="M17.3402 14.0391L13.7969 20.0435L19.387 14.5958L17.3402 14.0391Z"
                fill="url(#paint23_linear_15741_197433)"
            />
            <path
                d="M14.5333 18.7787L11.0234 15.3398L13.7961 20.0342L14.5333 18.7787Z"
                fill="url(#paint24_linear_15741_197433)"
            />
            <path
                d="M17.3408 14.0391H15.8445L14.5352 18.788L17.3408 14.0391Z"
                fill="url(#paint25_linear_15741_197433)"
            />
            <path
                d="M39.2024 5.18099C39.0373 5.08273 38.8722 5.03906 38.6742 5.03906C38.4981 5.03906 38.311 5.08273 38.168 5.17007C38.0029 5.26832 37.8928 5.39933 37.8048 5.574L31.7305 18.9694H32.5998C33.073 18.9694 33.5022 18.6964 33.7002 18.2707L34.9987 15.3885H42.4376L43.7031 18.2707C43.9012 18.7073 44.3303 18.9912 44.8035 18.9912H45.6508L39.5655 5.58492C39.4885 5.41025 39.3674 5.27924 39.2024 5.18099ZM41.8324 14.0785H35.571L35.582 14.0348L38.6852 7.0369L41.7994 14.0348L41.8324 14.0785Z"
                fill="#F3F3F3"
            />
            <path
                d="M56.6031 6.21522C55.7998 5.51652 54.6663 5.15625 53.2138 5.15625H48.559C48.3169 5.15625 48.1188 5.22175 47.9537 5.36368C47.8107 5.5056 47.7227 5.69119 47.7227 5.93137V18.9883H48.1298C48.7901 18.9883 49.3293 18.4534 49.3293 17.7983V13.2568H53.1918C54.6333 13.2568 55.7668 12.8965 56.5701 12.1869C57.3844 11.4664 57.7805 10.4838 57.7805 9.1956C57.8025 7.90737 57.4064 6.91391 56.6031 6.21522ZM53.0597 11.9358H49.3513V6.48814H53.0597C55.1725 6.48814 56.2289 7.39427 56.2289 9.20652C56.2069 11.0297 55.1615 11.9358 53.0597 11.9358Z"
                fill="#F3F3F3"
            />
            <path
                d="M61.0126 5.15625H60.6055V17.8092C60.6055 18.4643 61.1446 18.9992 61.8047 18.9992H62.2118V6.34622C62.2008 5.69119 61.6617 5.15625 61.0126 5.15625Z"
                fill="#F3F3F3"
            />
            <path
                d="M73.7641 13.1218C73.3239 12.5541 72.6856 12.1501 71.8493 11.9209C72.5536 11.6589 73.1148 11.2331 73.4999 10.6545C73.8961 10.065 74.1052 9.36626 74.1052 8.56931C74.1052 7.51035 73.731 6.64789 72.9938 6.03653C72.2565 5.40333 71.2551 5.09766 69.9896 5.09766C69.1753 5.09766 68.383 5.23958 67.6237 5.52342C67.2936 5.65443 66.9854 5.78544 66.7103 5.94919C66.1051 6.28762 65.94 7.07366 66.3142 7.64135L66.4462 7.81603C67.0185 7.34659 67.5907 7.00816 68.1629 6.7789C68.7351 6.54964 69.3293 6.44046 69.9456 6.44046C70.7599 6.44046 71.3871 6.63697 71.8273 7.04091C72.2675 7.43393 72.4986 8.00162 72.4986 8.74399C72.4986 9.58461 72.2235 10.2396 71.6842 10.6763C71.123 11.1348 70.3527 11.3423 69.3183 11.3423H68.5921C68.383 11.3423 68.2179 11.4078 68.0859 11.5388C67.9538 11.6698 67.9098 11.8335 67.9098 12.0082C67.9098 12.1829 67.9758 12.3466 68.0859 12.4777C68.2179 12.6087 68.383 12.6851 68.5921 12.6851H69.6265C71.7393 12.6851 72.8067 13.5257 72.8067 15.2069C72.8067 16.0366 72.5646 16.6698 72.0474 17.1065C71.5412 17.5432 70.8039 17.7725 69.8355 17.7725C69.1643 17.7725 68.537 17.6633 67.9648 17.445C67.3926 17.2157 66.7984 16.8773 66.1931 16.386L66.0611 16.5607C65.6649 17.1502 65.863 17.9253 66.4682 18.2637C66.7433 18.4275 67.0625 18.5585 67.3816 18.6677C68.1739 18.9624 68.9772 19.0934 69.8245 19.0934C71.2221 19.0934 72.3335 18.755 73.1368 18.0672C73.9511 17.3795 74.3473 16.4406 74.3473 15.2397C74.4133 14.41 74.2042 13.7004 73.7641 13.1218Z"
                fill="#F3F3F3"
            />
            <path
                d="M155.092 7.36553H149.447C149.37 7.36553 149.337 7.39828 149.337 7.4747V10.8044C149.337 10.8809 149.37 10.9136 149.447 10.9136H155.169C155.774 10.9136 156.314 11.4049 156.314 12.049C156.314 12.6167 155.818 13.1079 155.246 13.1079H149.458C149.381 13.1079 149.348 13.1407 149.348 13.2171V16.6997C149.348 16.7761 149.381 16.8089 149.458 16.8089H155.213C156.358 16.8089 157.315 17.7587 157.315 18.894C157.315 18.9705 157.282 19.0032 157.205 19.0032H147.026C146.949 19.0032 146.916 18.9705 146.916 18.894V5.32402C146.916 5.2476 146.949 5.21484 147.026 5.21484H157.084C157.161 5.21484 157.194 5.2476 157.194 5.32402C157.183 6.4594 156.27 7.36553 155.092 7.36553Z"
                fill="#F3F3F3"
            />
            <path
                d="M112.8 19.0372H111.767C110.932 19.0372 110.174 18.5467 109.823 17.7509L108.988 15.7887C108.988 15.756 108.955 15.7124 108.878 15.7124H102.45C102.417 15.7124 102.373 15.7451 102.34 15.7887L101.505 17.7945C101.164 18.5467 100.406 19.0808 99.56 19.0808H98.604C98.5271 19.0808 98.4941 19.0045 98.4941 18.9282L104.164 6.08692C104.395 5.56367 104.922 5.21484 105.493 5.21484H105.878C106.449 5.21484 106.977 5.55277 107.208 6.08692L112.877 18.9282C112.921 18.9609 112.877 19.0372 112.8 19.0372ZM105.614 8.2671L103.406 13.4014C103.373 13.4777 103.439 13.5541 103.516 13.5541H107.966C108.043 13.5541 108.076 13.4777 108.076 13.4014L105.834 8.2671C105.768 8.1908 105.647 8.1908 105.614 8.2671Z"
                fill="#F3F3F3"
            />
            <path
                d="M167.04 7.55731C167.04 7.51076 167.078 7.47302 167.125 7.47302H169.33C170.494 7.47302 171.437 6.52955 171.437 5.36573V5.29914C171.437 5.25258 171.399 5.21484 171.353 5.21484H161.873C160.942 5.21484 160.188 5.96962 160.188 6.90068V7.38873C160.188 7.43528 160.225 7.47302 160.272 7.47302H164.5C164.547 7.47302 164.584 7.51076 164.584 7.55731V18.9965C164.584 19.0431 164.622 19.0808 164.669 19.0808H165.608C166.399 19.0808 167.04 18.4393 167.04 17.6479V7.55731Z"
                fill="#F3F3F3"
            />
            <path
                d="M137.833 11.1772L143.458 5.35772C143.51 5.30423 143.472 5.21484 143.398 5.21484H141.217C140.827 5.21484 140.453 5.37449 140.183 5.65679L134.076 12.0358C134.023 12.0907 133.93 12.0535 133.93 11.9775V5.29914C133.93 5.25258 133.893 5.21484 133.846 5.21484H131.658C131.612 5.21484 131.574 5.25258 131.574 5.29914V18.9965C131.574 19.0431 131.612 19.0808 131.658 19.0808H132.498C133.289 19.0808 133.93 18.4393 133.93 17.6479V15.0597L136.169 12.8411L140.397 18.414C140.716 18.8341 141.212 19.0808 141.74 19.0808H143.684C143.753 19.0808 143.793 19.0009 143.751 18.9454L137.833 11.1772Z"
                fill="#F3F3F3"
            />
            <path
                d="M128.036 19.0808L124.293 13.8316C124.781 13.6995 125.224 13.5146 125.62 13.2769C126.029 13.026 126.379 12.7289 126.67 12.3855C126.96 12.029 127.184 11.6262 127.343 11.1772C127.515 10.715 127.6 10.1934 127.6 9.61234C127.6 8.93885 127.482 8.33139 127.244 7.78995C127.006 7.23531 126.663 6.77312 126.214 6.40336C125.778 6.02039 125.237 5.72987 124.59 5.53178C123.956 5.32049 123.243 5.21484 122.451 5.21484H116.861C116.536 5.21484 116.271 5.47901 116.271 5.80489V18.9965C116.271 19.0431 116.309 19.0808 116.356 19.0808H117.237C118.028 19.0808 118.669 18.4393 118.669 17.6479V14.3318C118.669 14.2853 118.707 14.2475 118.754 14.2475H121.758L124.661 18.3663C124.976 18.8143 125.49 19.0808 126.038 19.0808H128.036ZM125.125 9.751C125.125 10.4641 124.867 11.032 124.352 11.4545C123.837 11.8771 123.144 12.0884 122.273 12.0884H118.754C118.707 12.0884 118.669 12.0507 118.669 12.0041V7.60198C118.669 7.50888 118.745 7.4334 118.838 7.4334H122.253C123.164 7.4334 123.87 7.63149 124.372 8.02766C124.874 8.41062 125.125 8.98507 125.125 9.751Z"
                fill="#F3F3F3"
            />
            <path
                d="M92.6415 9.13694V16.9735C92.6415 18.1374 93.5848 19.0808 94.7485 19.0808H94.9933C95.0398 19.0808 95.0776 19.0431 95.0776 18.9965V5.34128C95.0776 5.27145 95.021 5.21484 94.9511 5.21484H93.6315C92.9156 5.21484 92.2486 5.57846 91.8607 6.18028L88.3274 11.6614C88.2942 11.7129 88.2188 11.7129 88.1857 11.6613L84.6677 6.18331C84.2801 5.57975 83.6121 5.21484 82.8949 5.21484H81.5776C81.5078 5.21484 81.4512 5.27145 81.4512 5.34128V18.9965C81.4512 19.0431 81.4889 19.0808 81.5355 19.0808H81.7407C82.9043 19.0808 83.8477 18.1374 83.8477 16.9735V9.17655L87.9058 15.1569C88.0735 15.4041 88.438 15.4031 88.6044 15.1551L92.6415 9.13694Z"
                fill="#F3F3F3"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_15741_197433"
                    x1="6.37807"
                    y1="23.925"
                    x2="4.46573"
                    y2="5.58515"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.094032" stopColor="#D8D8D8" />
                    <stop offset="0.2118" stopColor="#E9EAE9" />
                    <stop offset="0.3548" stopColor="#F6F6F6" />
                    <stop offset="0.5471" stopColor="#FDFDFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_15741_197433"
                    x1="24.4455"
                    y1="18.3331"
                    x2="9.55588"
                    y2="29.2373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.094032" stopColor="#D8D8D8" />
                    <stop offset="0.2118" stopColor="#E9EAE9" />
                    <stop offset="0.3548" stopColor="#F6F6F6" />
                    <stop offset="0.5471" stopColor="#FDFDFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_15741_197433"
                    x1="10.445"
                    y1="5.56091"
                    x2="27.5224"
                    y2="13.2249"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.094032" stopColor="#D8D8D8" />
                    <stop offset="0.2118" stopColor="#E9EAE9" />
                    <stop offset="0.3548" stopColor="#F6F6F6" />
                    <stop offset="0.5471" stopColor="#FDFDFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_15741_197433"
                    x1="8.44715"
                    y1="23.7042"
                    x2="6.94408"
                    y2="9.2914"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.113" stopColor="#C9CACA" />
                    <stop offset="0.3025" stopColor="#E1E1E1" />
                    <stop offset="0.5045" stopColor="#F2F2F2" />
                    <stop offset="0.7252" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_15741_197433"
                    x1="9.57137"
                    y1="7.5088"
                    x2="23.1052"
                    y2="13.5825"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.113" stopColor="#C9CACA" />
                    <stop offset="0.3025" stopColor="#E1E1E1" />
                    <stop offset="0.5045" stopColor="#F2F2F2" />
                    <stop offset="0.7252" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_15741_197433"
                    x1="23.2442"
                    y1="16.6932"
                    x2="11.4246"
                    y2="25.3491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.113" stopColor="#C9CACA" />
                    <stop offset="0.3025" stopColor="#E1E1E1" />
                    <stop offset="0.5045" stopColor="#F2F2F2" />
                    <stop offset="0.7252" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_15741_197433"
                    x1="11.1392"
                    y1="22.5498"
                    x2="8.24517"
                    y2="12.3781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.2097" stopColor="#CECECE" />
                    <stop offset="0.5176" stopColor="#E9E9E9" />
                    <stop offset="0.791" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_15741_197433"
                    x1="20.8438"
                    y1="14.9947"
                    x2="13.5192"
                    y2="22.64"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.2097" stopColor="#CECECE" />
                    <stop offset="0.5176" stopColor="#E9E9E9" />
                    <stop offset="0.791" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_15741_197433"
                    x1="9.27815"
                    y1="10.5261"
                    x2="19.7515"
                    y2="13.1582"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.2097" stopColor="#CECECE" />
                    <stop offset="0.5176" stopColor="#E9E9E9" />
                    <stop offset="0.791" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_15741_197433"
                    x1="9.58102"
                    y1="12.9728"
                    x2="17.377"
                    y2="13.5914"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.1841" stopColor="#BDBDBD" />
                    <stop offset="0.7286" stopColor="#EDEDED" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_15741_197433"
                    x1="13.205"
                    y1="21.058"
                    x2="9.8842"
                    y2="14.196"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.1841" stopColor="#BDBDBD" />
                    <stop offset="0.7286" stopColor="#EDEDED" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_15741_197433"
                    x1="18.5549"
                    y1="14.0379"
                    x2="14.3031"
                    y2="20.3916"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.1841" stopColor="#BDBDBD" />
                    <stop offset="0.7286" stopColor="#EDEDED" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_15741_197433"
                    x1="10.5808"
                    y1="14.9435"
                    x2="15.4888"
                    y2="13.0934"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.1748" stopColor="#A5A6A6" />
                    <stop offset="0.4035" stopColor="#B6B6B6" />
                    <stop offset="0.6621" stopColor="#D1D1D1" />
                    <stop offset="0.9409" stopColor="#F6F6F6" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_15741_197433"
                    x1="14.3881"
                    y1="19.3331"
                    x2="10.4529"
                    y2="16.0052"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.1748" stopColor="#A5A6A6" />
                    <stop offset="0.4035" stopColor="#B6B6B6" />
                    <stop offset="0.6621" stopColor="#D1D1D1" />
                    <stop offset="0.9409" stopColor="#F6F6F6" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_15741_197433"
                    x1="16.3891"
                    y1="13.8769"
                    x2="15.5005"
                    y2="18.9558"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.1748" stopColor="#A5A6A6" />
                    <stop offset="0.4035" stopColor="#B6B6B6" />
                    <stop offset="0.6621" stopColor="#D1D1D1" />
                    <stop offset="0.9409" stopColor="#F6F6F6" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_15741_197433"
                    x1="6.37811"
                    y1="23.9275"
                    x2="4.46577"
                    y2="5.58765"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.094032" stopColor="#D8D8D8" />
                    <stop offset="0.2118" stopColor="#E9EAE9" />
                    <stop offset="0.3548" stopColor="#F6F6F6" />
                    <stop offset="0.5471" stopColor="#FDFDFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_15741_197433"
                    x1="24.4455"
                    y1="18.3331"
                    x2="9.55588"
                    y2="29.2373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.094032" stopColor="#D8D8D8" />
                    <stop offset="0.2118" stopColor="#E9EAE9" />
                    <stop offset="0.3548" stopColor="#F6F6F6" />
                    <stop offset="0.5471" stopColor="#FDFDFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_15741_197433"
                    x1="10.445"
                    y1="5.56091"
                    x2="27.5224"
                    y2="13.2249"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.094032" stopColor="#D8D8D8" />
                    <stop offset="0.2118" stopColor="#E9EAE9" />
                    <stop offset="0.3548" stopColor="#F6F6F6" />
                    <stop offset="0.5471" stopColor="#FDFDFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_15741_197433"
                    x1="8.44715"
                    y1="23.7042"
                    x2="6.94408"
                    y2="9.2914"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.113" stopColor="#C9CACA" />
                    <stop offset="0.3025" stopColor="#E1E1E1" />
                    <stop offset="0.5045" stopColor="#F2F2F2" />
                    <stop offset="0.7252" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_15741_197433"
                    x1="23.2442"
                    y1="16.6932"
                    x2="11.4246"
                    y2="25.3491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.113" stopColor="#C9CACA" />
                    <stop offset="0.3025" stopColor="#E1E1E1" />
                    <stop offset="0.5045" stopColor="#F2F2F2" />
                    <stop offset="0.7252" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_15741_197433"
                    x1="11.1392"
                    y1="22.5498"
                    x2="8.24517"
                    y2="12.3781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.2097" stopColor="#CECECE" />
                    <stop offset="0.5176" stopColor="#E9E9E9" />
                    <stop offset="0.791" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_15741_197433"
                    x1="20.8438"
                    y1="14.9947"
                    x2="13.5192"
                    y2="22.64"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.2097" stopColor="#CECECE" />
                    <stop offset="0.5176" stopColor="#E9E9E9" />
                    <stop offset="0.791" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint22_linear_15741_197433"
                    x1="13.205"
                    y1="21.058"
                    x2="9.8842"
                    y2="14.196"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.1841" stopColor="#BDBDBD" />
                    <stop offset="0.7286" stopColor="#EDEDED" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint23_linear_15741_197433"
                    x1="18.5549"
                    y1="14.0379"
                    x2="14.3031"
                    y2="20.3916"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.1841" stopColor="#BDBDBD" />
                    <stop offset="0.7286" stopColor="#EDEDED" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint24_linear_15741_197433"
                    x1="14.3881"
                    y1="19.3331"
                    x2="10.4529"
                    y2="16.0052"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.1748" stopColor="#A5A6A6" />
                    <stop offset="0.4035" stopColor="#B6B6B6" />
                    <stop offset="0.6621" stopColor="#D1D1D1" />
                    <stop offset="0.9409" stopColor="#F6F6F6" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint25_linear_15741_197433"
                    x1="16.3891"
                    y1="13.8769"
                    x2="15.5005"
                    y2="18.9558"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.1748" stopColor="#A5A6A6" />
                    <stop offset="0.4035" stopColor="#B6B6B6" />
                    <stop offset="0.6621" stopColor="#D1D1D1" />
                    <stop offset="0.9409" stopColor="#F6F6F6" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
            </defs>
        </svg>
    );
};
