import { ComponentPropsWithoutRef } from 'react';

export const LogoMobile = (props: ComponentPropsWithoutRef<'svg'>) => {
    return (
        <svg width="105" height="20" viewBox="0 0 105 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5259 0.111539C11.4398 -0.0377831 11.2241 -0.0370343 11.1389 0.112925L0.0294586 19.6661C-0.0550361 19.8149 0.0523079 19.9996 0.223313 19.9996H22.6175C22.7892 19.9996 22.8965 19.8136 22.8106 19.6649L11.5259 0.111539ZM9.10671 12.7918L9.10614 12.7909L9.10681 12.7918L13.0885 11.7024L12.0122 15.6585L12.0119 15.659L9.10681 12.7918"
                fill="#F3F3F3"
            />
            <path
                d="M0.223313 19.9998H4.7233L8.86169 4.12109L0.0294586 19.6664C-0.0550361 19.8151 0.0523079 19.9998 0.223313 19.9998Z"
                fill="#F3F3F3"
            />
            <path
                d="M20.6012 15.8369L4.72314 19.9998H22.6173C22.7891 19.9998 22.8964 19.8137 22.8105 19.6651L20.6012 15.8369Z"
                fill="#F3F3F3"
            />
            <path
                d="M18.165 13.4052L20.6014 15.8367L11.526 0.111592C11.4399 -0.0377293 11.2243 -0.0370859 11.1391 0.112862L8.86182 4.12092L18.1551 13.3953L18.165 13.4052Z"
                fill="#F3F3F3"
            />
            <path d="M4.72314 19.9986L7.99491 19.1409L7.97655 7.51562L4.72314 19.9986Z" fill="#F3F3F3" />
            <path d="M7.97656 7.51675L18.1648 13.4054L8.86159 4.12109L7.97656 7.51675Z" fill="#F3F3F3" />
            <path d="M7.99463 19.1419L20.601 15.8368H20.6009L18.1645 13.4053L7.99463 19.1419Z" fill="#F3F3F3" />
            <path d="M7.99542 19.1425L10.1776 17.9116L7.98096 9.9834L7.99542 19.1425Z" fill="#F3F3F3" />
            <path d="M18.1654 13.406L16.0201 12.166L10.1777 17.9118L18.1654 13.406Z" fill="#F3F3F3" />
            <path d="M7.98096 9.98279L16.0199 12.1652L7.97705 7.5166L7.98096 9.98279Z" fill="#F3F3F3" />
            <path
                d="M13.0889 11.7022L14.3233 11.7042L7.98096 9.98242L8.45458 11.6918L13.0777 11.7022H13.0889Z"
                fill="#F3F3F3"
            />
            <path
                d="M9.09502 12.7695L8.45508 11.6914L10.1781 17.9102L11.3985 16.71L9.10776 12.7912L9.09502 12.7695Z"
                fill="#F3F3F3"
            />
            <path d="M14.3232 11.7051L11.3979 16.7113L16.0198 12.1657L14.3232 11.7051Z" fill="#F3F3F3" />
            <path d="M13.088 11.7018L8.45361 11.6914L9.10633 12.7913L13.088 11.7018Z" fill="#F3F3F3" />
            <path d="M12.0111 15.6582L9.10596 12.791L11.3967 16.7097L12.0111 15.6582Z" fill="#F3F3F3" />
            <path d="M14.3226 11.7042L13.0882 11.7021L12.0117 15.6595L14.3226 11.7042Z" fill="#F3F3F3" />
            <path
                d="M0.223313 19.9998H4.7233L8.86169 4.12109L0.0294586 19.6664C-0.0550361 19.8151 0.0523079 19.9998 0.223313 19.9998Z"
                fill="url(#paint0_linear_5086_16461)"
            />
            <path
                d="M20.6012 15.8369L4.72314 19.9998H22.6173C22.7891 19.9998 22.8964 19.8138 22.8105 19.6651L20.6012 15.8369Z"
                fill="url(#paint1_linear_5086_16461)"
            />
            <path
                d="M18.165 13.4052L20.6014 15.8367L11.526 0.111524C11.4399 -0.0377644 11.2243 -0.0370157 11.1391 0.112827L8.86182 4.12089L18.1551 13.3953L18.165 13.4052Z"
                fill="url(#paint2_linear_5086_16461)"
            />
            <path
                d="M4.72314 19.9986L7.99491 19.1409L7.97655 7.51562L4.72314 19.9986Z"
                fill="url(#paint3_linear_5086_16461)"
            />
            <path
                d="M7.97656 7.51675L18.1648 13.4054L8.86159 4.12109L7.97656 7.51675Z"
                fill="url(#paint4_linear_5086_16461)"
            />
            <path
                d="M7.99463 19.1419L20.601 15.8368H20.6009L18.1645 13.4053L7.99463 19.1419Z"
                fill="url(#paint5_linear_5086_16461)"
            />
            <path
                d="M7.99542 19.1425L10.1776 17.9116L7.98096 9.9834L7.99542 19.1425Z"
                fill="url(#paint6_linear_5086_16461)"
            />
            <path
                d="M18.1654 13.406L16.0201 12.166L10.1777 17.9118L18.1654 13.406Z"
                fill="url(#paint7_linear_5086_16461)"
            />
            <path
                d="M7.98096 9.98282L16.0199 12.1652L7.97705 7.5166L7.98096 9.98282Z"
                fill="url(#paint8_linear_5086_16461)"
            />
            <path
                d="M13.0889 11.7022L14.3233 11.7043L7.98096 9.98242L8.45458 11.6918L13.0777 11.7022H13.0889Z"
                fill="url(#paint9_linear_5086_16461)"
            />
            <path
                d="M9.09502 12.7695L8.45508 11.6914L10.1781 17.9103L11.3985 16.71L9.10776 12.7913L9.09502 12.7695Z"
                fill="url(#paint10_linear_5086_16461)"
            />
            <path
                d="M14.3232 11.7051L11.3979 16.7112L16.0198 12.1656L14.3232 11.7051Z"
                fill="url(#paint11_linear_5086_16461)"
            />
            <path
                d="M13.088 11.7018L8.45361 11.6914L9.10633 12.7913L13.088 11.7018Z"
                fill="url(#paint12_linear_5086_16461)"
            />
            <path
                d="M12.0111 15.6582L9.10596 12.791L11.3967 16.7097L12.0111 15.6582Z"
                fill="url(#paint13_linear_5086_16461)"
            />
            <path
                d="M14.3226 11.7042L13.0882 11.7021L12.0117 15.6595L14.3226 11.7042Z"
                fill="url(#paint14_linear_5086_16461)"
            />
            <path
                d="M90.8748 6.01489H86.1705C86.1063 6.01489 86.0788 6.04218 86.0788 6.10587V8.88065C86.0788 8.94433 86.1063 8.97162 86.1705 8.97162H90.939C91.4433 8.97162 91.8927 9.38101 91.8927 9.91778C91.8927 10.3909 91.48 10.8002 91.0032 10.8002H86.1797C86.1155 10.8002 86.088 10.8275 86.088 10.8912V13.7934C86.088 13.857 86.1155 13.8843 86.1797 13.8843H90.9757C91.9294 13.8843 92.7272 14.6758 92.7272 15.622C92.7272 15.6857 92.6997 15.713 92.6355 15.713H84.153C84.0889 15.713 84.0614 15.6857 84.0614 15.622V4.31363C84.0614 4.24995 84.0889 4.22266 84.153 4.22266H92.5346C92.5988 4.22266 92.6263 4.24995 92.6263 4.31363C92.6171 5.25979 91.856 6.01489 90.8748 6.01489Z"
                fill="#F3F3F3"
            />
            <path
                d="M55.6316 15.7413H54.7709C54.075 15.7413 53.4432 15.3325 53.1502 14.6694L52.4543 13.0342C52.4543 13.007 52.4268 12.9707 52.3627 12.9707H47.0062C46.9787 12.9707 46.9421 12.9979 46.9146 13.0342L46.2187 14.7057C45.9348 15.3325 45.303 15.7776 44.598 15.7776H43.8014C43.7373 15.7776 43.7098 15.7141 43.7098 15.6505L48.4346 4.94938C48.6269 4.51335 49.0664 4.22266 49.5425 4.22266H49.863C50.3391 4.22266 50.7787 4.50426 50.9709 4.94938L55.6957 15.6505C55.7323 15.6777 55.6957 15.7413 55.6316 15.7413ZM49.6432 6.76621L47.8028 11.0448C47.7753 11.1084 47.8302 11.172 47.8943 11.172H51.6027C51.6668 11.172 51.6943 11.1084 51.6943 11.0448L49.8264 6.76621C49.7714 6.70262 49.6707 6.70262 49.6432 6.76621Z"
                fill="#F3F3F3"
            />
            <path
                d="M100.832 6.17471C100.832 6.13592 100.863 6.10447 100.902 6.10447H102.74C103.71 6.10447 104.496 5.31825 104.496 4.34839V4.2929C104.496 4.25411 104.464 4.22266 104.425 4.22266H96.5256C95.7498 4.22266 95.1209 4.85163 95.1209 5.62752V6.03423C95.1209 6.07302 95.1524 6.10447 95.1912 6.10447H98.7148C98.7535 6.10447 98.785 6.13592 98.785 6.17471V15.7074C98.785 15.7462 98.8164 15.7776 98.8552 15.7776H99.6376C100.297 15.7776 100.832 15.243 100.832 14.5835V6.17471Z"
                fill="#F3F3F3"
            />
            <path
                d="M76.4929 9.1913L81.1807 4.34172C81.2238 4.29714 81.1922 4.22266 81.1302 4.22266H79.3134C78.9877 4.22266 78.6762 4.35569 78.451 4.59094L73.3618 9.90681C73.318 9.95255 73.2408 9.92155 73.2408 9.85823V4.2929C73.2408 4.25411 73.2094 4.22266 73.1706 4.22266H71.3476C71.3088 4.22266 71.2773 4.25411 71.2773 4.2929V15.7074C71.2773 15.7462 71.3088 15.7776 71.3476 15.7776H72.0469C72.7063 15.7776 73.2408 15.243 73.2408 14.5835V12.4267L75.1065 10.5779L78.6295 15.2219C78.8951 15.572 79.3092 15.7776 79.7486 15.7776H81.3685C81.4267 15.7776 81.4596 15.7111 81.4244 15.6648L76.4929 9.1913Z"
                fill="#F3F3F3"
            />
            <path
                d="M68.3281 15.7776L65.2087 11.4033C65.6158 11.2932 65.9844 11.1391 66.3145 10.9411C66.6556 10.732 66.9472 10.4844 67.1893 10.1982C67.4314 9.9011 67.6184 9.56546 67.7505 9.1913C67.8935 8.80613 67.965 8.37145 67.965 7.88724C67.965 7.32599 67.866 6.81978 67.6679 6.36858C67.4699 5.90638 67.1838 5.52122 66.8097 5.21308C66.4466 4.89395 65.9954 4.65184 65.4563 4.48677C64.9281 4.31069 64.3339 4.22266 63.6738 4.22266H59.0159C58.7444 4.22266 58.5242 4.4428 58.5242 4.71436V15.7074C58.5242 15.7462 58.5557 15.7776 58.5945 15.7776H59.3286C59.988 15.7776 60.5225 15.243 60.5225 14.5835V11.8201C60.5225 11.7814 60.554 11.7499 60.5928 11.7499H63.0961L65.5152 15.1822C65.7784 15.5556 66.2066 15.7776 66.6633 15.7776H68.3281ZM65.9019 8.00279C65.9019 8.59704 65.6873 9.07025 65.2582 9.4224C64.8291 9.77455 64.2514 9.95063 63.5252 9.95063H60.5928C60.554 9.95063 60.5225 9.91918 60.5225 9.88038V6.21194C60.5225 6.13435 60.5854 6.07145 60.663 6.07145H63.5087C64.2679 6.07145 64.8566 6.23653 65.2747 6.56667C65.6928 6.8858 65.9019 7.36451 65.9019 8.00279Z"
                fill="#F3F3F3"
            />
            <path
                d="M38.8326 7.49107V14.0216C38.8326 14.9914 39.6187 15.7776 40.5884 15.7776H40.7924C40.8312 15.7776 40.8627 15.7462 40.8627 15.7074V4.32802C40.8627 4.26983 40.8155 4.22266 40.7573 4.22266H39.6576C39.061 4.22266 38.5052 4.52567 38.1819 5.02718L35.2375 9.59477C35.2098 9.63772 35.147 9.63767 35.1194 9.59467L32.1878 5.02971C31.8647 4.52674 31.3081 4.22266 30.7104 4.22266H29.6127C29.5545 4.22266 29.5073 4.26983 29.5073 4.32802V15.7074C29.5073 15.7462 29.5388 15.7776 29.5776 15.7776H29.7486C30.7183 15.7776 31.5044 14.9914 31.5044 14.0216V7.52408L34.8861 12.5077C35.0259 12.7137 35.3297 12.7129 35.4683 12.5062L38.8326 7.49107Z"
                fill="#F3F3F3"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5086_16461"
                    x1="9.53238"
                    y1="18.5045"
                    x2="4.74227"
                    y2="4.30417"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.0948988" stopColor="#D7D8D7" />
                    <stop offset="0.212581" stopColor="#E9E9E9" />
                    <stop offset="0.355404" stopColor="#F5F5F5" />
                    <stop offset="0.547581" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5086_16461"
                    x1="0.631264"
                    y1="13.8605"
                    x2="-0.0833582"
                    y2="23.6988"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.0948988" stopColor="#D7D8D7" />
                    <stop offset="0.212581" stopColor="#E9E9E9" />
                    <stop offset="0.355404" stopColor="#F5F5F5" />
                    <stop offset="0.547581" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5086_16461"
                    x1="6.29496"
                    y1="14.1836"
                    x2="21.0996"
                    y2="17.807"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C3C4C3" />
                    <stop offset="0.0948988" stopColor="#D7D8D7" />
                    <stop offset="0.212581" stopColor="#E9E9E9" />
                    <stop offset="0.355404" stopColor="#F5F5F5" />
                    <stop offset="0.547581" stopColor="#FCFCFC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_5086_16461"
                    x1="8.55431"
                    y1="17.3545"
                    x2="2.17036"
                    y2="13.1803"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.114546" stopColor="#C9CACA" />
                    <stop offset="0.303782" stopColor="#E0E1E1" />
                    <stop offset="0.50531" stopColor="#F1F1F1" />
                    <stop offset="0.725645" stopColor="#FBFBFB" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_5086_16461"
                    x1="5.16676"
                    y1="11.3751"
                    x2="15.9545"
                    y2="17.1611"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.114546" stopColor="#C9CACA" />
                    <stop offset="0.303782" stopColor="#E0E1E1" />
                    <stop offset="0.50531" stopColor="#F1F1F1" />
                    <stop offset="0.725645" stopColor="#FBFBFB" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_5086_16461"
                    x1="11.3649"
                    y1="11.6851"
                    x2="8.74626"
                    y2="20.8763"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.114546" stopColor="#C9CACA" />
                    <stop offset="0.303782" stopColor="#E0E1E1" />
                    <stop offset="0.50531" stopColor="#F1F1F1" />
                    <stop offset="0.725645" stopColor="#FBFBFB" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_5086_16461"
                    x1="10.217"
                    y1="13.7368"
                    x2="6.25069"
                    y2="12.9408"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.211828" stopColor="#CDCECE" />
                    <stop offset="0.518815" stopColor="#E8E9E9" />
                    <stop offset="0.791553" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_5086_16461"
                    x1="12.828"
                    y1="10.3009"
                    x2="9.08077"
                    y2="17.8031"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.211828" stopColor="#CDCECE" />
                    <stop offset="0.518815" stopColor="#E8E9E9" />
                    <stop offset="0.791553" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_5086_16461"
                    x1="5.79782"
                    y1="11.2641"
                    x2="12.3958"
                    y2="16.1904"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B7B8B8" />
                    <stop offset="0.211828" stopColor="#CDCECE" />
                    <stop offset="0.518815" stopColor="#E8E9E9" />
                    <stop offset="0.791553" stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_5086_16461"
                    x1="6.98207"
                    y1="11.6872"
                    x2="10.2418"
                    y2="15.1693"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.187095" stopColor="#BCBDBD" />
                    <stop offset="0.729612" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_5086_16461"
                    x1="12.257"
                    y1="14.6172"
                    x2="7.79768"
                    y2="12.5682"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.187095" stopColor="#BCBDBD" />
                    <stop offset="0.729612" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_5086_16461"
                    x1="12.8984"
                    y1="9.7767"
                    x2="8.96826"
                    y2="14.745"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ABACAC" />
                    <stop offset="0.187095" stopColor="#BCBDBD" />
                    <stop offset="0.729612" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_5086_16461"
                    x1="11.9407"
                    y1="12.9385"
                    x2="12.2546"
                    y2="10.8544"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.174311" stopColor="#A4A5A5" />
                    <stop offset="0.402447" stopColor="#B5B5B5" />
                    <stop offset="0.660365" stopColor="#D0D0D0" />
                    <stop offset="0.938425" stopColor="#F5F5F5" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_5086_16461"
                    x1="12.9064"
                    y1="13.9166"
                    x2="9.16254"
                    y2="12.1894"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.174311" stopColor="#A4A5A5" />
                    <stop offset="0.402447" stopColor="#B5B5B5" />
                    <stop offset="0.660365" stopColor="#D0D0D0" />
                    <stop offset="0.938425" stopColor="#F5F5F5" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_5086_16461"
                    x1="12.3046"
                    y1="10.9276"
                    x2="10.4213"
                    y2="14.571"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FA0A0" />
                    <stop offset="0.174311" stopColor="#A4A5A5" />
                    <stop offset="0.402447" stopColor="#B5B5B5" />
                    <stop offset="0.660365" stopColor="#D0D0D0" />
                    <stop offset="0.938425" stopColor="#F5F5F5" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
            </defs>
        </svg>
    );
};
