import { Text, Spacer } from '@chakra-ui/react';
import Logos from '../custom/Logos';
import styled from 'styled-components';
import tokens from 'design-tokens';
import { breakpoints } from '../theme';
import { DapiType } from '../types';
import { MARKET_URL } from '../constants';

interface Props {
    dapi: DapiType;
    index: number;
    chainName: string;
}

const getMarketUrl = (chainName: string, dapiName: string) => `${MARKET_URL}/${chainName}?search=${dapiName}`;

export const DApiRow = ({ dapi, index, chainName }: Props) => {
    const dapiNameParts = dapi.name.split('/');

    return (
        <StyledLink key={index} href={getMarketUrl(chainName, dapi.name)} target="_blank">
            <Logos dapiName={dapi.name} />
            <Text className="text">
                {dapiNameParts[0]}
                <span className="dapi-name-slash">/</span>
                {dapiNameParts[1]}
            </Text>
            <Spacer />
        </StyledLink>
    );
};

const StyledLink = styled.a`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${tokens.core.spacing[100]}px;
    color: ${tokens.semantic.color.text.default};
    font-size: ${tokens.core.font.size[100]}px;
    padding: ${tokens.core.spacing[100]}px;
    cursor: pointer;
    border-bottom: 1px solid #111b40;

    &:last-child {
        border-bottom: none;
    }

    .text {
        ${tokens.semantic.font.button[3]};
        color: ${tokens.core.color.gray[200]};
        line-height: 20px;
        flex: 1;
        transition: color 0.2s;

        .dapi-name-slash {
            color: ${tokens.core.color.gray[600]};
        }
    }

    .indicator {
        ${tokens.semantic.font.body[5]};
        color: ${tokens.core.color.gray[500]};
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        .text {
            color: ${tokens.core.color.indigo[300]};
        }
    }

    &:active {
        .text {
            color: ${tokens.core.color.indigo[700]};
        }
    }

    @media (min-width: ${breakpoints.xs}px) {
        flex-direction: row;
    }

    @media (min-width: ${breakpoints.s}px) {
        padding: 26px ${tokens.core.spacing[100]}px;
        gap: ${tokens.core.spacing[150]}px;

        .text {
            ${tokens.semantic.font.button[1]};
        }

        .indicator {
            ${tokens.semantic.font.body[8]};
        }
    }
`;
